import React, { useState, useEffect, useCallback } from 'react'
import { getActiveCompanyFromLocalStorage, setLocalStorage } from './auth'
import {
  getPermissionsForCurrentUser as getPermissionsForCurrentUserApiCall,
  listClients
} from '../services/graphQl/authorisation'
import {
  createSessionLog as createSessionLogApiCall,
  editSessionLog as editSessionLogApiCall
} from '../services/graphQl'
import { ContextSwitcherProps } from '@jdlt-ltd/pongo'
import { FileOrFolder } from './types'
import { useAuth0 } from '@auth0/auth0-react'
import { getJdltCookie } from './getOrSetJDLTCookie'
import LogRocket from 'logrocket'

type ContextProps = {
  currentUserClients: { [key: string]: string }[] | null
  setCurrentUserClients: any
  activeClient: ContextSwitcherProps['activeContext'] | null
  setActiveClient: React.Dispatch<
    React.SetStateAction<ContextSwitcherProps['activeContext']>
  >
  currentFolderPath: string
  setCurrentFolderPath: React.Dispatch<React.SetStateAction<string>>
  currentFolderData: FileOrFolder[]
  setCurrentFolderData: React.Dispatch<React.SetStateAction<FileOrFolder[]>>
  currentUserPermissions: string[]
  hasLoadedPermissions: boolean
  hasLoadedUserData: boolean
  hasSettingsPermissions: boolean
  isLogRocket: boolean
  setIsLogRocket: React.Dispatch<React.SetStateAction<boolean>>
  isSessionLogged: boolean
  setIsSessionLogged: React.Dispatch<React.SetStateAction<boolean>>
  isSessionUpdated: boolean
  setIsSessionUpdated: React.Dispatch<React.SetStateAction<boolean>>
  sessionId: string
  setSessionId: React.Dispatch<React.SetStateAction<string>>
  idToken: string | null
}

export const UserContext = React.createContext<ContextProps>({
  currentUserClients: null,
  setCurrentUserClients: () => [],
  activeClient: null,
  setActiveClient: () => null,
  currentFolderPath: '',
  setCurrentFolderPath: () => '',
  currentFolderData: [],
  setCurrentFolderData: () => null,
  currentUserPermissions: [],
  hasLoadedPermissions: false,
  hasLoadedUserData: false,
  hasSettingsPermissions: false,
  isLogRocket: false,
  setIsLogRocket: () => null,
  isSessionLogged: false,
  setIsSessionLogged: () => null,
  isSessionUpdated: false,
  setIsSessionUpdated: () => null,
  sessionId: '',
  setSessionId: () => null,
  idToken: null
})

type Props = {
  children: React.ReactNode
}

export const UserContextProvider: React.FC<Props> = ({ children }: Props) => {
  const [activeClient, setActiveClient] = useState<
    ContextSwitcherProps['activeContext'] | null
  >(null)
  const [currentUserClients, setCurrentUserClients] = useState<
    { [key: string]: string }[] | null
  >(null)
  const [currentFolderPath, setCurrentFolderPath] = useState('')
  const [currentFolderData, setCurrentFolderData] = useState<FileOrFolder[]>([])
  const [currentUserPermissions, setCurrentUserPermissions] = useState([])
  const [hasLoadedPermissions, setHasLoadedPermissions] = useState(false)
  const [hasLoadedUserData, setHasLoadedUserData] = useState(false)
  const [hasSettingsPermissions, setHasSettingsPermissions] = useState(false)
  const [isLogRocket, setIsLogRocket] = useState(false)
  const [sessionId, setSessionId] = useState('')
  const [isSessionLogged, setIsSessionLogged] = useState(false)
  const [isSessionUpdated, setIsSessionUpdated] = useState(false)
  const [idToken, setIdToken] = useState<string | null>(null)

  const { isAuthenticated, getIdTokenClaims, user } = useAuth0()

  const jdltCookie = getJdltCookie()

  const createSessionLog = useCallback(
    async (idToken: string) => {
      const session = await createSessionLogApiCall(idToken, {
        id: user?.sub || '',
        name: user?.name || '',
        email: user?.email || ''
      })
      setSessionId(session.id)
    },
    [user?.email, user?.name, user?.sub]
  )

  useEffect(() => {
    if (!isSessionLogged && user && idToken) {
      createSessionLog(idToken as string)
      setIsSessionLogged(true)
    }
  }, [user, idToken, isSessionLogged, createSessionLog, setIsSessionLogged])

  const editSessionLog = useCallback(
    async (
      idToken: string,
      sessionId: string,
      jdltCookie: string,
      logRocketUrl?: string
    ) => {
      return await editSessionLogApiCall(idToken, sessionId, {
        logRocketUrl: logRocketUrl || '',
        jdltTrackingCookie: jdltCookie
      })
    },
    []
  )

  useEffect(() => {
    if (
      !isSessionUpdated &&
      sessionId &&
      jdltCookie &&
      idToken &&
      isLogRocket
    ) {
      LogRocket.getSessionURL((sessionURL) =>
        editSessionLog(idToken, sessionId, jdltCookie, sessionURL)
      )
      setIsSessionUpdated(true)
    }
  }, [
    sessionId,
    isSessionLogged,
    jdltCookie,
    idToken,
    isLogRocket,
    isSessionUpdated,
    editSessionLog,
    setIsSessionUpdated
  ])

  useEffect(() => {
    const getToken = async () => {
      const tokenClaims: any = await getIdTokenClaims()
      setIdToken(tokenClaims.__raw)
    }
    isAuthenticated && getToken()
  }, [isAuthenticated, getIdTokenClaims])

  useEffect(() => {
    const getPermissionsForCurrentUser = async () => {
      const permissions = await getPermissionsForCurrentUserApiCall(
        idToken as string
      )
      setCurrentUserPermissions(permissions)
      setHasSettingsPermissions(permissions.includes('userManagement'))
      setHasLoadedPermissions(true)
    }
    idToken && getPermissionsForCurrentUser()
  }, [idToken])

  useEffect(() => {
    const fetchUserData = async () => {
      const clients = await listClients(idToken as string)
      setCurrentUserClients(clients)
      setHasLoadedUserData(true)

      if (!clients.length) return

      setActiveClient(
        getActiveCompanyFromLocalStorage() ||
          (clients as { [key: string]: string }[])[0]
      )
    }
    idToken && fetchUserData()
  }, [idToken])

  // When currentUserClients changes it will check if the current activeClient is still valid
  // and will reassign if not - making sure it is updated in local storage
  useEffect(() => {
    if (hasLoadedUserData) {
      setActiveClient((currentActiveClient) => {
        if (currentUserClients?.length) {
          const currentActiveClientFromLatestClientList =
            currentUserClients?.find(
              ({ id }: any) => currentActiveClient?.id === id
            )
          if (currentActiveClientFromLatestClientList) {
            setLocalStorage(
              'activeClient',
              currentActiveClientFromLatestClientList
            )
            return currentActiveClientFromLatestClientList
          } else {
            const { id, name } = (currentUserClients || [])[0]
            const newActiveClient = { id, name }
            setLocalStorage('activeClient', newActiveClient)
            return newActiveClient
          }
        } else {
          setLocalStorage('activeClient', null)
          return null
        }
      })
    }
  }, [currentUserClients, setActiveClient, hasLoadedUserData])

  const context = {
    currentUserClients,
    setCurrentUserClients,
    activeClient,
    setActiveClient,
    currentFolderPath,
    setCurrentFolderPath,
    currentUserPermissions,
    currentFolderData,
    setCurrentFolderData,
    hasLoadedPermissions,
    hasLoadedUserData,
    hasSettingsPermissions,
    isLogRocket,
    setIsLogRocket,
    isSessionLogged,
    setIsSessionLogged,
    isSessionUpdated,
    setIsSessionUpdated,
    sessionId,
    setSessionId,
    idToken
  }

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>
}
