const TestConfig = (): {} => {
  return {
    graphql: {
      server: {
        baseUrl: `https://8873nwpdgb.execute-api.eu-west-2.amazonaws.com/test`
      }
    },
    trackingId: 'UA-166331647-2',
    payment: {
      url: 'https://test.ipg-online.com/connect/gateway/processing',
      storename: '7220541088',
      urlRedirect:
        'https://r7wt7bzuudfzirfgucaxjm34vy0bkejo.lambda-url.eu-west-2.on.aws'
    }
  }
}

export default TestConfig()
